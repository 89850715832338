
// export const Contact = () => {
//     return(
//         <div>Contact Page
//             <div data-tf-live=“01HDKSZVAG41SSYZ965KVJEXCZ”></div><script src=“//embed.typeform.com/next/embed.js”></script>
//         </div>
//     )
// }


export const Contact = () => {
    return (
        <div>
            Contact Page
            <div data-tf-live="01HDKSZVAG41SSYZ965KVJEXCZ"></div>
            <script src="//embed.typeform.com/next/embed.js"></script>
        </div>
    );
}
